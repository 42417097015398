.nav {
    position: sticky;
    top: 0;
    z-index: 10;
}

@media screen and (min-width: 1440px) {
    .nav {
        margin: auto;
        width: 1440px;
    }
}

.header {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #1E1E1E;
}

.header-link {
    display: flex;
    align-items: center;
    margin: 28px 0 25px 96px;
    text-decoration: none;
}

.trence {
    padding-left: 16px;
    font-weight: 700;
    font-size: 37.5652px;
    line-height: 55px;
    color: #1E1E1E;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {}

@media screen and (min-width: 451px) and (max-width: 767px) {
    .header-link {
        margin: 28px 0 25px 66px;
    }
}

@media screen and (max-width: 450px) {
    .header-link {
        margin: 28px 0 25px 50px;
    }

    .trence {
        font-size: 30px;
        line-height: 5px;
        color: #1E1E1E;
    }

    .trence-logo {
        height: 30px;
    }
}