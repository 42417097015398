.product {
    padding: 110px 0 0 23px;
}

.product-wrapper {
    margin-left: 57px;
    padding: 29px 0 0 113px;
    border-left: 3px solid #1E1E1E;
    ;
}

.product-title {
    font-weight: 700;
    font-size: 64px;
    line-height: 82px;
    letter-spacing: -0.02em;
    color: #1E1E1E;
    text-align: center;

}

.product-content {
    display: flex;
    flex-direction: row;
    margin-top: 99px;
}

.gowagr {
    display: flex;
    flex-direction: row;
    margin: 22px 177px 0 0;
}

.gowagr-small {
    display: none;
}

.gowagr-img {
    margin-right: 21px;
    height: 46px;
}

.gowagr-body {
    text-align: center;
}

.gowagr-title {
    margin-bottom: 13px;
    font-weight: 700;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
}

.gowagr-web--link {
    background: #1E1E1E;
    padding: 5px 19px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.arrowcorner {
    margin-right: 10px;
}

.gowagr-plarge {
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #000000;
}

.gowagr-psmall {
    display: none;
}

.gowagrsample {
    margin-top: 78px;
}

.social-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 47px;
}

.social-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: 2px solid #000000;
    border-radius: 50%;
    margin-right: 51px;
}

.social-circle:last-child {
    margin-right: 0;
}

.pof {
    margin: 25px 0 41px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #000000;
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .product-wrapper {
        padding: 29px 0 0 0;

    }

    .product-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 70px;
    }

    .gowagr {
        display: flex;
        flex-direction: row;
        margin: 0;
    }

    .gowagr-small {
        display: none;
    }

    .gowagr-img {
        margin-right: 21px;
        height: 46px;
    }

    .gowagr-body {
        text-align: center;
    }

    .gowagr-title {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 36px;
        line-height: 46px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }

    .gowagr-web--link {
        background: #1E1E1E;
        padding: 5px 19px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .arrowcorner {
        margin-right: 10px;
    }

    .gowagr-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }

    .gowagr-psmall {
        display: none;
    }

    .gowagrsample {
        margin-top: 50px;
        width: 500px;
    }
}

@media screen and (min-width: 451px) and (max-width:768px) {
    .product {
        padding: 110px 0 0 13px;
    }

    .product-wrapper {
        padding: 29px 20px 0 20px;
        margin-left: 37px;
    }

    .product-title {
        font-size: 40px;
        line-height: 60px;
    }

    .product-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 70px;
    }

    .gowagr {
        display: none;
    }

    .gowagr-small {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gowagr-small--column {
        display: flex;
        flex-direction: row;
    }

    .gowagr-img {
        margin-right: 21px;
        height: 46px;
    }

    .gowagr-body {
        text-align: center;
    }

    .gowagr-title {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 46px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }

    .gowagr-web--link {
        background: #1E1E1E;
        padding: 5px 19px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .arrowcorner {
        margin-right: 10px;
    }

    .gowagr-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }

    .gowagr-plarge {
        display: none;
    }

    .gowagr-psmall {
        display: block;
        font-size: 15px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: -0.02em;
        color: #000000;
        text-align: center;
    }

    .gowagrsample {
        margin-top: 50px;
        width: 350px;
    }

    .social-circle {
        width: 50px;
        height: 50px;
    }

    .social-icon {
        width: 20px;
    }
}

@media screen and (max-width: 450px) {
    .product {
        padding: 85px 0 0 7px;
    }

    .product-wrapper {
        padding: 29px 20px 0 20px;
        margin-left: 23px;
    }

    .product-title {
        font-size: 30px;
        line-height: 50px;
    }

    .product-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }

    .gowagr {
        display: none;
    }

    .gowagr-small {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gowagr-small--column {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
    }

    .gowagr-img {
        margin-right: 21px;
        height: 46px;
    }

    .gowagr-body {
        text-align: center;
    }

    .gowagr-title {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 46px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }

    .gowagr-web--link {
        background: #1E1E1E;
        padding: 5px 19px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }

    .arrowcorner {
        margin-right: 10px;
    }

    .gowagr-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }

    .gowagr-plarge {
        display: none;
    }

    .gowagr-psmall {
        display: block;
        font-size: 15px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: -0.02em;
        color: #000000;
        text-align: center;
    }

    .gowagrsample {
        margin-top: 50px;
        width: 300px;
    }

    .social-circle {
        width: 45px;
        height: 45px;
    }

    .social-icon {
        width: 20px;
    }

    .pof {
        font-size: 15px;
        line-height: 20px;
    }
}