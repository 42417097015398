.landingpage {
  background: #f2c94c;
  padding: 110px 0 0 80px;
}

.landingpage-wrapper {
  padding: 129px 0 115px 133px;
  border-left: 3px solid #1e1e1e;
}

.landingpage-text h1 {
  font-weight: 700;
  font-size: 100px;
  line-height: 154px;
  letter-spacing: -0.02em;
  color: #1e1e1e;
  margin-right: 139px;
}

.landingpage-text p {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  margin-top: 17px;
  color: #1e1e1e;
}

.landingpage-text .pop {
  margin-right: 20px;
}

.arrow-right {
  display: flex;
  justify-content: flex-end;
  margin: 118px 86px 0 0;
}

.bounce {
  animation: bounce 2s infinite;
  cursor: pointer;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .landingpage-wrapper {
    padding: 129px 0 70px 30px;
  }

  .landingpage-text h1 {
    font-size: 64px;
    line-height: 100px;
    margin: 0;
  }

  .arrow-right {
    margin: 70px 86px 0 0;
  }
}

@media screen and (min-width: 451px) and (max-width: 768px) {
  .landingpage {
    padding: 110px 0 0 50px;
  }

  .landingpage-wrapper {
    padding: 100px 0 70px 20px;
  }

  .landingpage-text h1 {
    font-size: 30px;
    line-height: 50px;
    margin: 0;
  }

  .arrow-right {
    margin: 70px 86px 0 0;
  }
}

@media screen and (max-width: 450px) {
  .landingpage {
    padding: 110px 0 0 30px;
  }

  .landingpage-wrapper {
    padding: 100px 0 70px 20px;
  }

  .landingpage-text h1 {
    font-size: 24px;
    line-height: 44px;
    margin: 0;
  }

  .landingpage-text p {
    font-size: 12px;
    line-height: 19px;
  }

  .landingpage-text .pop {
    margin-right: 10px;
  }

  .arrow-right {
    margin: 70px 30px 0 0;
  }
}
