*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body {
    font-family: 'Lora';
    font-style: normal;
}

.home {
    margin-top: -110px;
}

@media screen and (min-width: 1440px) {
    .home {
        margin: -113px auto auto auto;
        width: 1440px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    
}
@media screen and (min-width: 451px) and (max-width: 767px) {}
@media screen and (max-width: 450px) {}