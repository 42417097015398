.footer {
    background: #1E1E1E;
    padding: 84px 0;
}

.footer-info {
    text-align: center;
}

.footer-info p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 22px;
}

.footer-info h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-bottom: 29px;
}

.footer-info h1:last-child {
    margin-bottom: 0;
}

@media screen and (max-width: 450px) {
    .footer-info p {
        font-size: 16px;
        line-height: 19px;
    }

    .footer-info h1 {
        font-size: 20px;
        line-height: 25px;
    }
}